/* Other implementations:
  - https://www.jquery-az.com/boots/demo.php?ex=52.0_3
  - https://www.primefaces.org/primereact/#/inputtext
  - https://getbootstrap.com/docs/4.1/examples/floating-labels/
  - https://github.com/tonystar/bootstrap-float-label
  - https://lusaxweb.github.io/vuesax/components/input.html#label-placeholder
    https://github.com/lusaxweb/vuesax/blob/v3.4.7/src/components/vsInput/main.styl
  - https://material-ui.com/demos/text-fields/#outlined
    https://github.com/mui-org/material-ui/blob/v3.1.1/packages/material-ui/src/OutlinedInput/OutlinedInput.js

  calc(move label at the top of the input - middle of the label text height)
  See $input-height-inner and $input-height https://github.com/twbs/bootstrap/blob/v4.1.3/scss/_variables.scss#L445-L446

  Why not include the font descent (distance between the font baseline and its bounding box bottom)?
  Problem: it differs from one font to another and cannot be computed in CSS
  See [TrueType fundamentals](https://docs.microsoft.com/en-us/typography/opentype/spec/ttch01)
  See [Meaning of top, ascent, baseline, descent, bottom, and leading in Android's FontMetrics](https://stackoverflow.com/a/27631737/990356)
  prettier-ignore
 */
$label-top-position: calc(
        #{- $font-size-base * $input-line-height} + #{$input-border-width}
        + #{$font-size-sm * $input-line-height * 1 * 0.5}
);

%label-inside-input {
  top: 0;
  left: 0;

  color: $input-placeholder-color;

  /* See https://github.com/twbs/bootstrap/blob/v4.1.3/scss/_variables.scss#L410 */
  padding: calc(#{$input-padding-y} + #{$input-border-width}) calc(#{$input-padding-x} + #{$input-border-width});

  /*
    Material-UI uses "pointer-preview: none" for TextField Outlined,
    see https://github.com/mui-org/material-ui/blob/v3.3.2/packages/material-ui/src/NativeSelect/NativeSelect.js#L77
    See ["the mouse event go "through" the element and target whatever is "underneath" that element instead"](https://developer.mozilla.org/en-US/docs/Web/CSS/pointer-events)
  */
  pointer-events: none;
}

/*
 Taken from https://accounts.google.com/signin/v2/identifier
 See https://github.com/twbs/bootstrap/blob/v4.1.3/scss/mixins/_transition.scss
 See https://github.com/twbs/bootstrap/blob/v4.1.3/scss/_variables.scss#L241
 */
$transition-fast: 150ms cubic-bezier(0.4, 0, 0.2, 1);

/* If a browser does not support a CSS pseudo-element, we cannot group/merge it with other CSS pseudo-elements:
  input:focus + label, input:-webkit-autofill + label { ... }   Does not work in Firefox
  Instead we have to ungroup/unmerge them:
  input:focus + label { ... }   Work in Firefox
  input:-webkit-autofill + label { ... }   Does not work in Firefox
//
  Sass does not allow to clone a placeholder class
  See https://github.com/sass/sass/issues/2312
  This is a hack:
  - if we want to group (share CSS) => use the placeholder class
  - if we want to ungroup (clone CSS) => use the mixin
 */
@mixin label-above-input {
/* Hack to hide the input border under the label */
background-color: $input-bg;

top: $label-top-position;

/* ch = width of the character "0", see https://developer.mozilla.org/en-US/docs/Web/CSS/length#Units */
padding: 0 0.7ch 0 0.7ch;

/* Compensate .7ch padding-left */
left: calc(#{$input-padding-x} + #{$input-border-width} - 0.7ch);

/* Visible when the label is above an input with a different background color */
border-radius: $border-radius;

font-size: $font-size-sm;

/* Same z-index as .form-control:focus, see https://github.com/twbs/bootstrap/blob/v4.3.1/scss/_input-group.scss#L36 */
z-index: 3;
}

%label-above-input {
@include label-above-input;
}

%label-common-css {
/* Breaks flexbox :-/
  ["An absolutely-positioned child of a flex container does not participate in flex layout"](https://stackoverflow.com/a/41033582/990356)
 */
position: absolute;
display: flex;
align-items: center;
/* Truncate the text label if larger than the input */
max-width: 100%;
@include text-truncate;
}

.floating-label {
  position: relative;

  > select + label {
    @extend %label-common-css;
    @extend %label-above-input;
  }

  /* Initial state: the label is inside the input */
  > input + label,
  > textarea + label {
    @extend %label-common-css;
    @include transition(all $transition-fast);
    @extend %label-inside-input;
  }

  /* Initial state: hide the placeholder */
  > input::placeholder,
  > textarea::placeholder {
  color: transparent;

  @include transition(color $transition-fast);
}

/* Except if <label> is before <input> => not a floating label */
> label + input::placeholder,
> label + textarea::placeholder {
  color: inherit;
}

/* When the input is focused: */
> input:focus,
> textarea:focus {
/* - show the placeholder
  FYI IE hides the placeholder when the input is focused, see https://stackoverflow.com/a/24319798/990356
 */
&::placeholder {
  color: inherit;
}

/* - move the label above the input */
+ label {
  @extend %label-above-input;
}
}
}

@import 'webkit-autofill';
@import 'placeholder-shown';
@import 'value-attr';
