.nav-item {
  font-family: $font-family-decorative;
  position: relative;

  .nav-link {
    font-weight: 400;
    color: theme-color("dark-gray");
    display: inline-flex;
    align-items: center;
    padding: .8rem .8rem;
    transition: color 0.5s;
  }

  .nav-link:hover {
    color: theme-color("primary");
  }

  &.active :after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background: theme-color("primary");
  }

  &.active .nav-link {
    font-weight: 500;

  }
}
