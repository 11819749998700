$main-box-shadow: #aaa !default;

$font-color-01: #fff !default;
$font-color-02: #9aaec1 !default;
$font-color-03: #54708b !default;

$border-color: #e9edf0 !default;
$highlighted: #e9edf0 !default;

$btn-bg: #e9edf0 !default;
$btn-bg-hover: #d5dadd !default;

$btn-bg2: #9aaec1 !default;
$btn-bg2-hover: #54708b !default;

$theme-green: theme-color("primary");

$theme-list: (
        'green': $theme-green,
);
