/* Variables */
$checkbox-size: 24px;
$checkbox-border: theme-color("gray");
$checkbox-selected: theme-color("primary");

.form-checkbox {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.checkbox {
  position: absolute;
  opacity: 0;

  /* Text */
  & + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: $checkbox-size;
    padding-left: $checkbox-size + 10;
    color: theme-color("dark-gray"); /* Label colour */
    margin-bottom: 0;

    /* Checkbox */
    &:before {
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      content: "";
      background: transparent;
      width: $checkbox-size;
      height: $checkbox-size;
      box-shadow: inset 0 0 0 1px $checkbox-border;
      border-radius: 4px;
      transition: 200ms ease-in-out all;
    }

    /* Tick */
    &:after {
      position: absolute;
      display: block;
      top: 5px;
      left: 9px;
      content: "";
      width: 6px;
      height: 11px;
      border-right: 2px solid transparent;
      border-bottom: 2px solid transparent;
      transform: rotate(45deg);
      transition: 200ms ease-in-out all;
    }
  }

  /* Hover */
  & + label:hover {
    color: #333;
  }

  /* Focus */
  & + label:focus {
    outline: none;
  }

  /* Checked */
  &:checked + label {
    &:before {
      background: $checkbox-selected;
      box-shadow: none;
    }
    &:after {
      border-color: white;
    }
  }

  /* Indeterminate */
  &:indeterminate + label {
    &:before {
      background: $checkbox-selected;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:after {
      content: "";
      width: 14px;
      height: 2px;
      background-color: white;
      transform: none;
      border: none;
      top: 11px;
      left: 5px;
    }
  }

  /* Disabled */
  &:disabled + label {
    &:before {
      background: #f2f2f2; /* Disabled colour */
      box-shadow: none;
    }
    &:after {
      border-color: transparent;
    }
  }

  /* Disabled Checked */
  &:checked:disabled + label {
    &:before {
      background: #f2f2f2;
      box-shadow: none;
    }
    &:after {
      border-color: #cecece;
    }
  }
}
