/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/bootstrap/variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "./assets/styles/global";

/****
  3rd party libs
****/
/* Floating labels */
@import 'assets/styles/bootstrap/custom/floating-label/bootstrap4-floating-label';

/* Datepicker styles */
@import "./assets/styles/bootstrap/custom/bs-datepicker/variables";
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import "./assets/styles/bootstrap/custom/bs-datepicker/bs-datepicker";

/* Pure */
@import "./assets/styles/custom/pure-checkbox/pure-checkbox";
@import "./assets/styles/custom/pure-radio/pure-radio";

/* Fonts */
@import "./assets/fonts/eventeeicons/eventeeicons.css";

/* Overlay */
@import 'node_modules/@angular/cdk/overlay-prebuilt.css';

/* Ng-select */
@import "./assets/styles/custom/ng-select/bootstrap_4.theme";

/* QuillJS */
@import "node_modules/quill/dist/quill.core.css";
@import "node_modules/quill/dist/quill.bubble.css";
@import "node_modules/quill/dist/quill.snow.css";
@import "./assets/styles/custom/quilljs/quilljs";

/* Toastr */
@import "./assets/styles/custom/ngx-toastr/ngx-toastr";

/* CropperJS */
@import "./assets/styles/custom/cropperjs/cropperjs";

/* ngx-ui-switch */
@import "node_modules/ngx-ui-switch/ui-switch.component";

/* DEVICES */
@import "assets/styles/custom/devices/devices";

/* Progress Materialize */
@import "assets/styles/custom/progressbar/progressbar";

/* Tachyons animate */
@import "node_modules/animate.css/animate.compat.css";
@import "assets/styles/custom/animate/animate-utils";

/* CDK Drag */
@import "./assets/styles/custom/cdk-drag-list/cdk-drop-list";
