@import "/node_modules/bootstrap/scss/functions";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";

/**
 * Variables Eventee
 */

/* Colors */

:root {
  --active-color: #42bba2;
  --active-color-placeholder-alpha: #8ae2d0;
  --background-color: #f5f4f4;
  --background-color-contrast: #000000;

  --linked-in: #0077B5;
  --facebook: #3b5998;
  --apple: #151515;
  --twitter: #1DA1F2;
  --x: #000000;
}

$primary: #42bba2;
$secondary: #5A415F;
$light: #f5f4f4;
$gray: #dbdbdb;
$darkGray: #74788D;
$dark: #484665;
$success: $primary;
$info: #ffffff;
$alert: #ffc107;
$warning: #f4b807;
$danger: #ef4061;
$white: #ffffff;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "light": $light,
        "dark": $dark,
        "gray": $gray,
        "dark-gray": $darkGray,
        "success": $success,
        "alert": $alert,
        "warning": $warning,
        "danger": $danger,
        "blue": #116EEE,
        "info": $info,
        "white": $white,
);

$body-min-width: 460px;
$body-bg: theme-color("light");
$text-color: theme-color("dark-gray");

$overflows: auto, hidden, scroll !default;

/* Typography
   Fonts */
$font-family-default: 'Roboto', sans-serif;
$font-family-decorative: 'Poppins', sans-serif;

$font-family-base: $font-family-default,
  /* Safari for OS X and iOS (San Francisco) */
-apple-system,
  /* Chrome < 56 for OS X (San Francisco) */
BlinkMacSystemFont,
  /* Windows */
"Segoe UI",
  /* Android */
"Roboto",
  /* Basic web fallback */
"Helvetica Neue", Arial, sans-serif,
  /* Emoji fonts */
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-size-base: 0.8125rem;
$font-size-sm: 0.7rem;
$font-weight-bold: 500;

/* Components
   Breakpoints */
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1023px,
    xl: 1335px,
    xxl: 1500px
);

$container-max-widths: (
    sm: 576px,
    md: 768px,
    lg: 920px,
    xl: 1140px,
    xxl: 1320px
);

/* Borders */
$border-radius: 10px;

/* Shadows */
$box-shadow-sm: 0px 5px 15px rgba(0, 0, 0, 0.02);
$box-shadow: 0 5px 15px 1px rgba(0, 0, 0, .08);
$box-shadow-lg: 0px 18px 23px 0 rgba(0,0,0,0.32);

/* Links */
$link-color: theme-color("primary");
$link-hover-color: darken(theme-color("primary"), 10%);
$link-hover-decoration: none;

/* Buttons */

$btn-border-radius: 6px;

/* Modals */
$modal-backdrop-opacity: 0.5;
$modal-content-box-shadow-sm-up: $box-shadow;
$modal-footer-border-width: 0;
$modal-header-border-width: 0;
$modal-sm: 350px;
$modal-content-border-radius: $border-radius;

/* Dropdowns */
$dropdown-border-radius: $border-radius;
$dropdown-font-size: $font-size-base;
$dropdown-link-active-bg: none;
$dropdown-link-active-color: theme-color("primary");
$dropdown-item-padding-y: 1.1rem;
$dropdown-link-hover-bg: transparentize(theme-color("primary"), 0.90);

/* Badges */
$badge-font-weight: $font-weight-light;
$badge-padding-y: 0.35em;
$badge-pill-padding-x: 1.2em;

/* Horizontal line */
$hr-margin-y: 0;

/* Padding */

/* Input */
$input-border-color: theme-color("gray");
$input-focus-border-color: theme-color("primary");
$input-padding-x: 1rem;
$input-padding-y: 1rem;
$input-font-size: $font-size-base;
$input-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
$input-placeholder-color: theme-color("dark-gray");
$input-focus-color: theme-color("dark");
$input-disabled-bg: #f7f8fa;
$input-border-radius: $border-radius;

/* Navbar */
$navbar-height: 65px;

$header-top: 65px;
$header-bottom: 52px;

$header-height: $header-top + $header-bottom;

/* Popover */
$popover-max-width: 350px;
$popover-border-width: 0;

/* HR */
$hr-border-color: theme-color("gray");
$hr-border-width: 1px;

/* Progressbar */
$progress-height: 0.5rem;
$progress-border-radius: 5px;

/* Table */
$table-border-color: theme-color("gray");

/* Pagination */

$pagination-color: theme-color("primary");
$pagination-active-color: white;
$pagination-active-bg: theme-color("primary");
$pagination-active-border-color: theme-color("primary");
$pagination-hover-color: theme-color("primary");
