
h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-decorative;
}

.text-underline {
  text-decoration: underline;
}

.text-small {
  font-size: 75%;
}

.icon-smaller {
  font-size: 18px;
  line-height: 18px;
}

.icon-larger, .icon-larger:before {
  font-size: 30px;
  line-height: 30px;
}

.icon-large, .icon-large:before {
  font-size: 20px;
  line-height: 20px;
}
