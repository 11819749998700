/* Generated by Glyphter (http://www.glyphter.com) on  Thu Apr 30 2020*/
@font-face {
    font-family: 'eventeeicons';
    src: url('eventeeicons.eot');
    src: url('eventeeicons.eot?#iefix') format('embedded-opentype'),
    url('eventeeicons.woff') format('woff'),
    url('eventeeicons.ttf') format('truetype'),
    url('eventeeicons.svg#eventeeicons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*='eventee-']:before {
    display: inline-block;
    font-family: 'eventeeicons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.eventee-icon-all:before {
    content: '\0041';
}

.eventee-icon-arrow-right:before {
    content: '\0042';
}

.eventee-icon-business-1:before {
    content: '\0043';
}

.eventee-icon-business:before {
    content: '\0044';
}

.eventee-icon-check:before {
    content: '\0045';
}

.eventee-icon-concert:before {
    content: '\0046';
}

.eventee-icon-conference:before {
    content: '\0047';
}

.eventee-icon-cross:before {
    content: '\0048';
}

.eventee-icon-delete:before {
    content: '\0049';
}

.eventee-icon-diamond:before {
    content: '\004a';
}

.eventee-icon-dislike:before {
    content: '\004b';
}

.eventee-icon-drag:before {
    content: '\004c';
}

.eventee-icon-drop:before {
    content: '\004d';
}

.eventee-icon-education:before {
    content: '\004e';
}

.eventee-icon-enterprise:before {
    content: '\004f';
}

.eventee-icon-event-detail:before {
    content: '\0050';
}

.eventee-icon-feedback:before {
    content: '\0051';
}

.eventee-icon-free:before {
    content: '\0052';
}

.eventee-icon-halls:before {
    content: '\0053';
}

.eventee-icon-health:before {
    content: '\0054';
}

.eventee-icon-info-big:before {
    content: '\0055';
}

.eventee-icon-info:before {
    content: '\0056';
}

.eventee-icon-letter:before {
    content: '\0057';
}

.eventee-icon-like:before {
    content: '\0058';
}

.eventee-icon-link:before {
    content: '\0059';
}

.eventee-icon-lock-open:before {
    content: '\005a';
}

.eventee-icon-lock:before {
    content: '\0061';
}

.eventee-icon-map:before {
    content: '\0062';
}

.eventee-icon-marketing:before {
    content: '\0063';
}

.eventee-icon-microphone:before {
    content: '\0064';
}

.eventee-icon-newspaper:before {
    content: '\0065';
}

.eventee-icon-others:before {
    content: '\0066';
}

.eventee-icon-participants:before {
    content: '\0067';
}

.eventee-icon-premium:before {
    content: '\0068';
}

.eventee-icon-questions:before {
    content: '\0069';
}

.eventee-icon-technology:before {
    content: '\006a';
}

.eventee-icon-text:before {
    content: '\006b';
}

.eventee-icon-training:before {
    content: '\006c';
}

.eventee-icon-warning big:before {
    content: '\006d';
}

.eventee-icon-12th:before {
    content: '\006e';
}

.eventee-icon-pin:before {
    content: '\006f';
}

.eventee-icon-api:before {
    content: '\0070';
}

.eventee-icon-networking:before {
    content: '\0071';
}

.eventee-icon-newsfeed:before {
    content: '\0072';
}

.eventee-icon-whitelist:before {
    content: '\0073';
}

.eventee-icon-invited:before {
    content: '\0074';
}

.eventee-icon-attendee:before {
    content: '\0075';
}

.eventee-icon-observers:before {
    content: '\0076';
}

.eventee-icon-polls:before {
    content: '\0077';
}

.eventee-icon-question:before {
    content: '\0078';
}

.eventee-icon-inactive-polling:before {
    content: '\0079';
}

.eventee-icon-agenda-scale:before {
    content: '\007a';
}

.eventee-icon-user-import:before {
    content: '\0030';
}

.eventee-icon-restrict-anonymous:before {
    content: '\0031';
}

.eventee-icon-workshop:before {
    content: '\0032';
}

.eventee-icon-hide-reviews:before {
    content: '\0033';
}

.eventee-icon-live-stream:before {
    content: '\0034';
}

.eventee-icon-unlimited:before {
    content: '\0035';
}

.eventee-icon-workshop-2:before {
    content: '\0036';
}

.eventee-icon-custom-branding:before {
    content: '\0037';
}

.eventee-icon-inperson-event:before {
    content: '\0038';
}

.eventee-icon-virtual-event:before {
    content: '\0039';
}

.eventee-icon-sponsor:before {
    content: '\0021';
}

.eventee-icon-exhibitor:before {
    content: '\0022';
}

.eventee-icon-partners:before {
    content: '\0023';
}

.eventee-icon-email-language:before {
    content: '\0024';
}

.eventee-icon-sponsor-dollar:before {
    content: '\0025';
}
