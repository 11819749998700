
.modal-dialog {
    display: flex;
    align-items: center;
    min-height: subtract(100%, $modal-dialog-margin * 2);

    /* Ensure `modal-dialog-centered` extends the full height of the view (IE10/11) */
    &::before {
      display: block; /* IE10 */
      height: subtract(100vh, $modal-dialog-margin * 2);
      content: "";
    }

}

@include media-breakpoint-up(sm) {
  .modal-dialog {
    min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    &::before {
      height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
    }
  }
}

.modal-body {
  border-top: 1px solid theme-color("gray");
  border-bottom: 1px solid theme-color("gray");
}

.modal-content {
  border: 0;
}

modal-container:nth-of-type(2) {
  background: rgba(0, 0, 0, 0.5);
}

.modal-md {
  max-width: 700px;
}

.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.fade {
  opacity: 0;
}