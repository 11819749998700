.bs-datepicker {
  box-shadow: $box-shadow;
}

.bs-datepicker-container {
  padding: 0;
}

.bs-datepicker-body table th {
  padding-bottom: 0.5rem;
  color: theme-color("dark-gray");
  font-family: $font-family-decorative;
}

.bs-datepicker-body table th.weekday {
  font-weight: 500;
}

.bs-datepicker-body table td {
  color: theme-color("dark")
}

.bs-datepicker-head button.current, .bs-datepicker-head button, .bs-datepicker-body table.months td span {
  border-radius: $border-radius;
  font-family: $font-family-decorative;
  font-weight: 500;
}

.bs-datepicker-body table.years td span {
  font-family: $font-family-decorative;
  font-weight: 500;
}

.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
  text-decoration: line-through;
}

.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  display: none;
}
