@import "../../bootstrap/variables";

.cdk-drop-list .cdk-drag-placeholder {
  opacity: 0.6;
}

.cdk-drag-preview {
  background: white;
  box-shadow: $box-shadow;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.cdk-drop-list-dragging > *:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
