/* Variables */
$border-color: theme-color("dark-gray");
$accent-color: theme-color("primary");

/* Radio */
.radio {
  display: inline-block;
  position: relative;
  margin: 0 0 10px;

  label {
    margin-top: 2px;
    margin-bottom: 0;
  }

  &-input {
    position: absolute;
    top: 4px;
    left: 0;
    width: 36px;
    height: 20px;
    opacity: 0;
    z-index: 0;
  }

  /* Unchecked  */
  &-label {
    display: block;
    padding: 0 0 0 24px;
    cursor: pointer;
    color: theme-color("dark-gray");

    &:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: transparent;
      border: 2px solid $border-color;
      border-radius: 14px;
      z-index: 1;
      transition: border-color 0.28s cubic-bezier(.4, 0, .2, 1);
    }

    &:after {
      content: '';
      position: absolute;
      top: 8px;
      left: 4px;
      width: 8px;
      height: 8px;
      background-color: $accent-color;
      border-radius: 50%;
      z-index: 2;
      transform: scale(0, 0);
      transition: transform 0.28s cubic-bezier(.4, 0, .2, 1);
    }
  }

  /* Checked */
  &-input:checked + &-label {
    color: #495057;

    &:before {
      border-color: $accent-color;
    }

    &:after {
      transform: scale(1, 1);
    }
  }

  /* Focus
     Uncomment for accessible outline
     &-input:focus + &-label {
         &:before {
             outline: 1px dotted currentColor;
         }
     }
   */
}
