@import "../../bootstrap/variables";

/* Position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: calc(var(--header-height) + var(--subheader-height) + 12px) !important;
  right: 12px;
}

.toast-dashboard-top-right {
  top: calc(var(--header-height) + 12px);
  right: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 66px;
  left: 12px;
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

/* Toast */
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;

  .ngx-toastr {
    @include border-radius($alert-border-radius);
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 0.75rem 1.25rem 0.75rem 50px;
    width: 300px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    box-shadow: $box-shadow;
    pointer-events: auto;
    transition: 0.5s box-shadow;

    .toast-title {
      font-weight: bold;
    }

    .toast-message {
      word-wrap: break-word;
    }

    .toast-message a:hover {
      text-decoration: underline;
    }

    .toast-close-button {
      position: relative;
      right: -0.3em;
      top: -0.3em;
      float: right;
      font-size: 20px;
      font-weight: bold;
      color: inherit;
      text-shadow: 0 1px 0 #fff;
      opacity: 0.5;
      background: transparent;
      border: 0;
      padding: 0;
    }

    .toast-progress {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      background-color: #000000;
      opacity: 0.3;
    }

    .toast-close-button:hover,
    .toast-close-button:focus {
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.75;
    }
  }

  .ngx-toastr:hover {
    box-shadow: $box-shadow-lg;
    opacity: 1;
    cursor: pointer;
  }

  .toast-success {
    background-color: theme-color("success");
  }
}

@function str-split($string, $separator) {
  $split-arr: ();
  $index: str-index($string, $separator);
  @while $index != null {
    $item: str-slice($string, 1, $index - 1);
    $split-arr: append($split-arr, $item);
    $string: str-slice($string, $index + 1);
    $index: str-index($string, $separator);
  }
  $split-arr: append($split-arr, $string);

  @return $split-arr;
}

@function svg-factory($fill-color, $viewbox, $path) {
  $split: str-split($viewbox, ' ');
  $width: nth($split, 3);
  $height: nth($split, 4);

  /*
    opacity is 0.9999 otherwise it uses a hex equivalent
    firefox requires fill rgb
   */
  @return "%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='" + $viewbox +
    "' width='" + $width + "' height='" + $height + "'%3E%3Cpath fill='" +
    rgba($fill-color, 0.999999) + "' d='" + $path + "'/%3E%3C/svg%3E";
}

@function svg-encode($svg) {
  @return 'data:image/svg+xml;charset=utf8,' + $svg;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
