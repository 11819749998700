.form-control:focus:placeholder-shown {
  color: $input-placeholder-color;
}

.form-control:focus:not(:placeholder-shown) {
  color: $input-focus-color;
}

.form-control.hidden-placeholder::placeholder {
  opacity: 0;
}

.form-control.ng-touched.ng-invalid, ng-select.ng-touched.ng-invalid .ng-select-container {
  border-color: theme-color("danger");
  animation: shake 150ms 4 linear, scale 0.5s 2 ease-in-out;
  -moz-animation: shake 150ms 4 linear, scale 0.5s 2 ease-in-out;
  -webkit-animation: shake 150ms 4 linear, scale 0.5s 2 ease-in-out;
  -o-animation: shake 150ms 4 linear, scale 0.5s 2 ease-in-out;
}

.invalid-feedback {
  animation: shake 150ms 4 linear, scale 0.5s 2 ease-in-out;
  -moz-animation: shake 150ms 4 linear, scale 0.5s 2 ease-in-out;
  -webkit-animation: shake 150ms 4 linear, scale 0.5s 2 ease-in-out;
  -o-animation: shake 150ms 4 linear, scale 0.5s 2 ease-in-out;
}

@keyframes shake {
  0% {
    transform: translate(3px, 0);
  }
  50% {
    transform: translate(-3px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@-moz-keyframes shake {
  0% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.05);
  }
  100% {
    -moz-transform: scale(1);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@-ms-keyframes shake {
  0% {
    -ms-transform: scale(1);
  }
  50% {
    -ms-transform: scale(1.05);
  }
  100% {
    -ms-transform: scale(1);
  }
}

