.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.cursor-move {
  cursor: move !important;
}


.cursor-not-allowed {
  cursor: not-allowed !important;
}

.h-100 {
  height: 100%;
}

.rounded-border {
  border-radius: 5px;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.border-gray {
  border: 1px solid theme-color("gray");
}

.border-bottom-gray {
  border-bottom: 1px solid theme-color("gray");
}

.border-top-gray {
  border-top: 1px solid theme-color("gray");
}

.flex-basis-0 {
  flex-basis: 0;
}

@include media-breakpoint-up(md) {
  .container-mobile {
    width: calc(#{500px} - 2rem);
  }
}

@include media-breakpoint-down(md) {
  .container-mobile {
    width: calc(#{$body-min-width} - 2rem);
  }
}

.scroll-modal-container {
  max-height: calc(80vh - 4rem);
}

.transition-all {
  transition: all 0.5s;
}

.object-cover {
  object-fit: cover;
}

.prevent-pointer-events {
  pointer-events: none;
}

.timed-message {
  opacity: 0;
  animation: slide-fade-in-out 4s ease-in-out 1 forwards;

  @keyframes slide-fade-in-out {
    0% {
      opacity: 0;
      transform: translateY(-100%);
      -webkit-transform: translateY(-100%);
    }
    15% {
      opacity: 1;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
    85% {
      opacity: 1;
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
      -webkit-transform: translateY(100%);
    }
  }
}

.line-height-1 {
  line-height: 1;
}
