@import "./bootstrap/variables";
@import "./bootstrap/mixins";
@import "./bootstrap/backgrounds";
@import "./bootstrap/badge";
@import "./bootstrap/buttons";
@import "./bootstrap/dropdown";
@import "./bootstrap/modals";
@import "./bootstrap/popover";
@import "./bootstrap/typography";
@import "./bootstrap/table";
@import "./bootstrap/nav";
@import "./bootstrap/text";
@import "./bootstrap/shadows";
@import "./bootstrap/utils";
@import "./bootstrap/forms";
/* Styles (maybe move to utils) */

::selection {
  color: white;
  background: theme-color("primary"); /* WebKit/Blink Browsers */
}

::-moz-selection {
  color: white;
  background: theme-color("primary"); /* Gecko Browsers */
}

:root {
  --header-height: 65px;
  --subheader-height: 52px;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

body {
  min-width: $body-min-width;
  min-height: calc(100vh - var(--header-height));
}

.sub-header-margin {
  @include media-breakpoint-down(md) {
    padding-bottom: var(--header-height) !important;
  }

}

.h-auto-subheader {
  height: calc(100vh - var(--subheader-height) - var(--header-height) - #{rem-to-px(3rem)});
}

.min-h-auto-subheader {
  min-height: calc(100vh - var(--subheader-height) - var(--header-height) - #{rem-to-px(3rem)});
}

.popover {
  z-index: $zindex-modal + 2;
}

.handle {
  touch-action: none
}
.cdk-overlay-container {
  z-index: 9999 !important;
}