@import "../../../assets/styles/bootstrap/variables";
@import "../../../assets/styles/bootstrap/functions";

.table {

  table-layout: fixed;

  tbody tr:hover {
    th, td {
      color: theme-color("primary");
    }
  }

  tr.active {
    th, td {

      &:first-child {
        position: relative;
      }

      &:first-child:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        height: 100%;
        width: 6px;
        background: theme-color("primary");
      }

      color: theme-color("primary");
      font-weight: $font-weight-bold;
    }
  }

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: middle;
    font-weight: $font-weight-normal;
    color: theme-color("dark-gray");
    transition: border 0.1s;

    &:first-child {
      padding-left: 1.5rem;
    }

    &:last-child {
      padding-right: 1.5rem;
    }
  }

  thead th {
    vertical-align: bottom;
    border: none;
    font-family: $font-family-decorative;
    font-weight: $font-weight-bold;
    color: black;

    &:hover i {
      color: theme-color("primary");
    }
  }

  tbody + tbody {
    border: none;
  }
}
