.badge {
  font-family: 'Poppins', sans-serif;
  font-size: 0.7rem;
}

.badge-circle {
  border-radius: 50%;
  height: 23px;
  width: 23px;
  line-height: 16px;
  font-size: 10px;
  padding-left: 0;
  padding-right: 0;
}

.badge-circle-small {
  width: 10px;
  height: 10px;
}
