.btn {
  font-size: $font-size-base;
  font-family: $font-family-decorative;
  font-weight: 500;
  white-space: nowrap;
}

.btn-circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
}

.btn-h-50 {
  height: 50px;
}

.btn-icon i {
  font-size: 1.5em;
}

.btn-icon .mdi:before, .btn-icon .mdi {
  font-size: 19px;
  line-height: 19px;
}

.btn-action {
  color: theme-color("dark-gray");
  font-weight: 400;
}

.btn-action:hover {
  color: theme-color("primary");
}

.btn-action.active {
  color: theme-color("primary");
  font-weight: 500;
}

$facebook: #3b5998;

.btn-facebook {
  display: flex;
  justify-content: center;
  background: $facebook;
  color: white;

  img {
    margin-right: 0.5rem;
  }

  &:hover, &:focus, &:active {
    background: darken($facebook, 10%);
    color: white;
  }
}

$instagram: #e33e5c;

.btn-instagram {
  display: flex;
  justify-content: center;
  background: $instagram;
  color: white;

  img {
    margin-right: 0.5rem;
  }

  &:hover, &:focus, &:active {
    background: darken($instagram, 10%);
    color: white;
  }
}

$linkedin: #0077B5;

.btn-linkedin {
  display: flex;
  justify-content: center;
  background: $linkedin;
  color: white;

  img {
    margin-right: 0.5rem;
  }

  &:hover, &:focus, &:active {
    background: darken($linkedin, 10%);
    color: white;
  }
}

.btn-apple {
  display: flex;
  justify-content: center;
  background: #000000;
  color: white;

  img {
    height: 20px;
    margin-right: 0.5rem;
  }

  &:hover, &:focus, &:active {
    background: lighten(#000000, 10%);
    color: white;
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

button:disabled {
  cursor: not-allowed;
}
