@import "node_modules/@ng-select/ng-select/scss/mixins";

$ng-select-highlight: theme-color("primary");
$ng-select-primary-text: #333 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: $input-border-color !default;
$ng-select-border-radius: $border-radius !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: transparentize(theme-color("primary"), 0.90) !default;
$ng-select-marked: transparentize(theme-color("primary"), 0.90) !default;
$ng-select-box-shadow: $box-shadow;
$ng-select-placeholder: $input-placeholder-color !default;
$ng-select-height: $input-height;
$ng-select-value-padding-left: $input-padding-x !default;
$ng-select-value-font-size: 0.9em !default;

.floating-select {
  position: relative;

  label {
    background-color: #fff;
    top: calc(-1.21875rem + 1px + 0.525rem);
    padding: 0 0.7ch 0 0.7ch;
    left: calc(1rem + 1px - 0.7ch);
    border-radius: 5px;
    font-size: 0.7rem;
    z-index: 1001;
    position: absolute;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #74788D;
  }
}

.ng-select {

  &.ng-select-opened {
    > .ng-select-container {
      background: $ng-select-bg;
      border-color: theme-color("primary");

      &:hover {
        box-shadow: none;
      }

      .ng-arrow {
        top: -2px;
        border-color: transparent transparent darken($ng-select-border, 20);
        border-width: 0 5px 5px;

        &:hover {
          border-color: transparent transparent darken($ng-select-border, 60);
        }
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ng-select-highlight;
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-text;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    background-color: $ng-select-bg;
    border-radius: $ng-select-border-radius;
    border: 1px solid $ng-select-border;
    min-height: $ng-select-height;
    align-items: center;

    &:hover {
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    }

    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;
      @include rtl {
        padding-right: $ng-select-value-padding-left;
        padding-left: 0;
      }

      .ng-placeholder {
        color: $ng-select-placeholder;
        font-weight: $input-font-weight;
        line-height: $input-line-height;
        font-size: $input-font-size;
        font-family: $input-font-family;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;

      .ng-value-container {
        .ng-input {
          top: 0;
          left: 0;
          padding-left: $ng-select-value-padding-left;
          padding-right: 35px;
          height: 100%;
          display: flex;
          @include rtl {
            padding-right: $ng-select-value-padding-left;
            padding-left: 50px;
          }
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 1px solid lighten($ng-select-border, 10);

        .ng-value-label {
          padding: 0 5px;
        }
      }
    }

    .ng-select-container {
      .ng-value-container {
        padding-top: 5px;
        padding-left: 7px;
        @include rtl {
          padding-right: 7px;
          padding-left: 0
        }

        .ng-value {
          font-size: $ng-select-value-font-size;
          margin-bottom: 5px;
          background-color: $ng-select-selected;
          border-radius: 2px;
          margin-right: 5px;
          @include rtl {
            margin-right: 0;
            margin-left: 5px;
          }

          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;

            .ng-value-label {
              padding-left: 5px;
              @include rtl {
                padding-left: 0;
                padding-right: 5px;
              }
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 1px 5px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 1px 5px;

            &:hover {
              background-color: darken($ng-select-selected, 5);
            }

            &.left {
              border-right: 1px solid darken($ng-select-selected, 10);
              @include rtl {
                border-left: 1px solid darken($ng-select-selected, 10);
                border-right: none;
              }
            }

            &.right {
              border-left: 1px solid darken($ng-select-selected, 10);
              @include rtl {
                border-left: 0;
                border-right: 1px solid darken($ng-select-selected, 10);
              }
            }
          }
        }

        .ng-input {
          padding: 0 0 3px 3px;
          font-weight: $input-font-weight;
          line-height: $input-line-height;
          font-size: $input-font-size;
          color: $input-placeholder-color;
          font-family: $input-font-family;
          @include rtl {
            padding: 0 3px 3px 0;
          }
        }

        .ng-placeholder {
          top: 5px;
          padding-bottom: 5px;
          padding-left: 3px;
          font-weight: $input-font-weight;
          line-height: $input-line-height;
          font-size: $input-font-size;
          color: $input-placeholder-color;
          font-family: $input-font-family;
          @include rtl {
            padding-right: 3px;
            padding-left: 0;
          }
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: darken($ng-select-border, 20);

    &:hover .ng-clear {
      color: #D0021B;
    }
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;

    @include rtl {
      padding: 5px 0 0 5px;
    }
  }

  .ng-arrow-wrapper {
    width: 25px;
    padding-right: 28px;
    @include rtl {
      padding-left: 5px;
      padding-right: 0;
    }

    &:hover {
      .ng-arrow {
        border-top-color: darken($ng-select-border, 40);
      }
    }

    .ng-arrow {
      border-color: darken($ng-select-border, 20) transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
    }
  }
}

.ng-dropdown-panel {
  background-color: $ng-select-bg;
  border: 1px solid $input-border-color;
  box-shadow: $box-shadow;
  left: 0;
  overflow: hidden;

  &.ng-select-bottom {
    top: 100%;
    border-radius: $border-radius;
    margin-top: 10px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:last-child {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }

  &.ng-select-top {
    bottom: 100%;
    border-radius: $border-radius;
    margin-bottom: 20px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
      }

      &.ng-option-selected, &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-selected;
        font-weight: 600;
      }
    }

    .ng-option {
      background-color: $ng-select-bg;
      color: rgba(0, 0, 0, .87);
      padding: 1.1rem 1.5rem;
      font-family: $font-family-decorative;
      border-top: 1px theme-color("light") solid;

      &.ng-option-selected, &.ng-option-selected.ng-option-marked {
        color: $ng-select-primary-text;
        background-color: $ng-select-selected;

        .ng-option-label {
          font-weight: 600;
        }
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-primary-text;
      }

      &.ng-option-disabled {
        color: lighten($ng-select-primary-text, 60);
      }

      &.ng-option-child {
        padding-left: 22px;
        @include rtl {
          padding-right: 22px;
          padding-left: 0;
        }
      }

      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
        @include rtl {
          padding-left: 5px;
          padding-right: 0;
        }
      }
    }
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}
