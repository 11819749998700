@use "sass:math";

$resX: 1125px;
$resY: 2436px;
$notch-height: 90px;
$notch-width: 627px;
$notch-border-radius: 60px;
$bezels: 48px;
$screen-border-radius: 120px;
$phone-border-radius: 160px;
$scale: 4;
$indicator-top-margin: 10px;
$margins: 36px;

$iphone-color: #120d30;
$notch-color: $iphone-color;
$screen-background: linear-gradient(#4a3972, #5e4a9d);
$indicators-color: white;
$background: #FECA45;

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: 'Open Sans', sans-serif;

  &__description {
    font-size: math.div(80px, $scale);
    padding-bottom: 20px;
  }

  &__phone {
    position: relative;

    width: math.div($resX + $bezels * 2, $scale);
    height: math.div($resY + $bezels * 2, $scale);
    padding-top: 1px;

    background-color: $iphone-color;

    border-radius: math.div($phone-border-radius, $scale);
  }

  &__notch {
    position: absolute;
    top: math.div($bezels, $scale);
    left: math.div(($resX - $notch-width) * 0.5 + $bezels, $scale);

    height: math.div($notch-height, $scale);
    width: math.div($notch-width, $scale);

    background-color: $notch-color;

    border-radius: 0 0 math.div($notch-border-radius, $scale) math.div($notch-border-radius, $scale);
  }

  &__screen {
    position: relative;

    height: math.div($resY, $scale);
    width: math.div($resX, $scale);

    padding-top: math.div(100px, $scale);

    background: $screen-background;

    border-radius: math.div($screen-border-radius, $scale);
    cursor: grab;
    user-select: none;
    perspective: 700px;
  }

  &__screen-wrapper {
    position: relative;

    height: math.div($resY, $scale);
    width: math.div($resX, $scale);
    margin-top: math.div($bezels, $scale) - 1px;
    margin-left: math.div($bezels, $scale);
    overflow: hidden;

    border-radius: math.div($screen-border-radius, $scale);
  }

  &__indicator {
    position: absolute;
    top: math.div($indicator-top-margin, $scale);
    font-size: math.div(44px, $scale);
    color: $indicators-color;

    &--time {
      left: math.div(75px, $scale);
    }

    &--signal {
      right: math.div(180px, $scale);
    }

    &--battery {
      font-size: math.div(50px, $scale);
      right: math.div(70px, $scale);
    }
  }

  &__controls {
    position: relative;
    display: flex;
    justify-content: center;

    padding: math.div(25px, $scale) 0;

    font-size: math.div(75px, $scale);

    color: $indicators-color;
  }

  &__arrow {
    position: absolute;
    left: math.div(50px, $scale);
  }

  &__card {
    position: relative;

    margin: math.div($margins, $scale);
    height: math.div(570px, $scale);
    width: calc(100% - #{math.div($margins, $scale) * 2});

    font-size: math.div(70px, $scale);

    border-radius: math.div(20px, $scale);
    transform-style: preserve-3d;

    &--notched {
      position: absolute;
      top: 0;

      transform: rotateX(180deg) scale(0.6) translateY(math.div(700px, $scale));
    }

    &--processing {
      position: absolute;
      top: 0;

      transform: translateY(math.div(250px, $scale));
    }
  }

  &__card-frontside {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
    padding-top: math.div($margins, $scale);
    padding-bottom: math.div($margins * 0.5, $scale);

    background-color: white;

    border-radius: math.div(20px, $scale);
    backface-visibility: hidden;
  }


  &__card-backside {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    color: white;
    background: linear-gradient($notch-color 50%, lighten($notch-color, 10%));

    border-radius: math.div(math.div($notch-border-radius, $scale), 0.6);
    transform: rotateX(180deg);
    backface-visibility: hidden;
  }

  &__card-release {
    padding: math.div(20px, $scale);
    opacity: 0;

    transition: opacity 0.2s ease;
  }

  &__card-type {
    padding-left: math.div($margins * 2, $scale);

    font-size: math.div(50px, $scale);

    color: #aaa;
  }

  &__card-body {
    display: flex;
    align-items: center;
    padding-left: math.div($margins * 2, $scale);
    padding-right: math.div($margins * 2, $scale);
    padding-top: math.div($margins, $scale);
    padding-bottom: math.div($margins, $scale);
  }

  &__card-picture {
    width: math.div(200px, $scale);
    height: math.div(200px, $scale);
    flex-shrink: 0;

    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &--starbucks {
      background-image: url(https://kiyutink.github.io/logos/starbucks.svg);
    }

    &--citi {
      background-image: url(https://kiyutink.github.io/logos/citi.png);

    }

    &--paypal {
      background-image: url(https://kiyutink.github.io/logos/paypal.svg);
    }

    &--apple {
      background-image: url(https://kiyutink.github.io/logos/apple.png);
    }
  }

  &__card-info {
    width: 70%;

    margin-left: math.div($margins, $scale);
    margin-right: math.div($margins, $scale);
  }

  &__card-caption {
    width: 100%;

    font-weight: 700;
  }

  &__card-description {
    min-height: math.div(150px, $scale);

    font-size: math.div(55px, $scale);
  }

  &__card-money {
    font-size: math.div(70px, $scale);
    font-weight: 700;
  }

  &__card-bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
  }

  &__card-button {
    padding-top: math.div($margins * 0.5, $scale);
    padding-left: math.div($margins * 2, $scale);

    color: #6b6281;

    cursor: pointer;
  }

  &__card-time {
    padding-top: math.div($margins, $scale);
    padding-right: math.div($margins * 2, $scale);

    font-size: math.div(50px, $scale);

    color: #ddd;
  }
}
