.dropdown-menu {
  box-shadow: $box-shadow;
  border: none;
  overflow: hidden;
  padding: 0;
  margin-top: 10px;
  z-index: $zindex-fixed + 3;
}

.dropdown-center .dropdown-item {
  justify-content: center;
}

.dropdown-toggle {
  font-family: $font-family-decorative;
}

.dropdown-item {
  display: flex;
  align-items: center;
  font-family: $font-family-decorative;
  border-top: 1px theme-color("light") solid;
}

.dropdown-item:first-child {
  border-top: none;
}

.dropdown-footer {
  display: flex;
  padding: 1.1rem 1.5rem;
  border-top: 1px theme-color("light") solid;
  border-bottom: 1px theme-color("light") solid;
}

.dropdown-header.header-image-background {
  background: url(../../../assets/images/patterns/lowpoly-dark.png);
  background-size: cover;
  padding: 2rem 1rem;
}
